<template>
  <div class="detail">
    <div class="theme">
      {{title}}
    </div>
    <div class="body">
      <div class="list" v-for="(item, index) in answerList" :key="index">
        <div class="title">{{ index + 1 }}. {{ item.question }}</div>
        <div class="radios-con">
          <van-radio-group
            v-model="answerList[index].options"
            direction="horizontal"
            disabled
          >
            <van-radio
              :name="pindex"
              v-for="(pitem, pindex) in options"
              :key="pindex"
            >
              {{ pitem }}
              <template #icon="props">
                <img
                  class="img-icon"
                  :src="props.checked ? icon.active : icon.normal"
                />
              </template>
            </van-radio>
          </van-radio-group>
          <div class="text-area" v-if="answerList[index].options == 2">
            <van-field
              maxlength="200"
              v-model="answerList[index].answer"
              autosize
              type="textarea"
              contenteditable="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import LoadList from "@/components/SearchLoadList";
import { getQuestionDetail } from "../api";
export default {
  components: {
    LoadList,
  },
  data() {
    return {
      radio: 0,
      options: ["满意", "基本满意", "不满意"],
      detail: {},
      title:'',
      answerList: [],
      icon: {
        normal: require("@/assets/images/home/noSelect.png"),
        active: require("@/assets/images/home/select.png"),
      },
    };
  },
  created() {
    let id = this.$route.params.id;
    this.init(id);
  },
  methods: {
    init(id) {
      let params = {
        id: id,
      };
      getQuestionDetail(params).then((res) => {
        console.log("radio", res);
        this.title = res.data.title
        // this.list = res.data.items.map((e) => {
        //   return e.question;
        // });
        this.answerList = res.data.items;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.detail {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  box-sizing: border-box;
  .theme {
    width: 100%;
    padding: 0.4rem .3rem 0;
    color: #333333;
    font-size: 0.34rem;
    font-weight: 700;
    text-align: center;
    line-height: 0.6rem;
  }
  .body {
    margin-top: 0.2rem;
    width: 100%;
    padding: 0 0.26rem;
    .list {
      width: 100%;
      padding: 0.2rem 0.1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .title {
        color: #3b93ff;
        font-size: 0.32rem;
      }
      .radios-con {
        font-size: 0.28rem;
        /deep/ .van-radio-group {
          margin-top: .2rem;
        }
        .text-area {
          width: 100%;
          margin-top: 0.2rem;
          border-radius: 0.2rem;
          border: 1px solid rgba(153, 153, 153,0.2);
        }
      }
    }
  }
  
}
</style>